@import 'styles/base.scss';

.base {
  width: 540px;
}

.header {
  padding: 0 24px;
  margin-bottom: 0px;
}

.body {
  padding: 24px;

  .threshold {
    width: 92px;
  }
}

.footer {
  padding: 0 24px 24px;
  margin-top: 16px;
}

.autoApproveTitle {
  @include font($size: 18px, $lineHeight: 24px, $color: midnight, $weight: semibold);
  margin-top: 24px;
}

.manualReviewTitle {
  margin-top: 8px;
  @include font($size: 18px, $lineHeight: 24px, $color: midnight, $weight: semibold);
}

.submissionLabel {
  padding-top: 16px;
  margin-left: 8px;
  @include font($size: 16px, $lineHeight: 24px, $color: midnight, $weight: medium);
}

.hint {
  margin-top: 16px;
  @include font($size: 14px, $lineHeight: 24px, $color: grey-9, $weight: medium);
}

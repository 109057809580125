@import 'styles/base.scss';
.table {
  overflow: auto;
  min-height: 220px !important;
}

.referralNumber {
  @include font($size: 14px, $lineHeight: 24px, $weight: bold);
  text-decoration: underline;
}

.row {
  @include row(center, center);
  gap: 2px;
}

.active {
  color: map-get($colors, actionable-green);
}

.completed,
.accepted {
  color: map-get($colors, midnight);
}

.revoked {
  color: map-get($colors, warning);
}

.expired,
.draft {
  color: map-get($colors, grey-8);
}
